// Fonts
@import url("https://fonts.googleapis.com/css?family=Amiri|Poppins:wght@400;700&display=swap");
$font-secondary: "Amiri";
$font-primary: "Poppins", sans-serif;

// Font Sizes
$base-fontsize: 18px;
$base-fontsize-inc: 1.333;
$base-fontsize-small: $base-fontsize / $base-fontsize-inc;
$base-fontsize-h4: $base-fontsize * $base-fontsize-inc;
$base-fontsize-h3: $base-fontsize-h4 * $base-fontsize-inc;
$base-fontsize-h2: $base-fontsize-h3 * $base-fontsize-inc;
$base-fontsize-h1: $base-fontsize-h2 * $base-fontsize-inc;

// Font Styles

$font-weight-bold: 700;

// Colors

$white: #ffffff;
$black: #000000;
$color-primary: #c5dd34;
$color-secondary: #c5dd34;
$neutral: #fbf7ef;
$neutral-med: lighten(#52535f, 50%);
$neutral-dark: #52535f;

// Flexbox Grid
$grid-max-width: 1440px;

$flexboxgrid-grid-columns: 12;
$flexboxgrid-gutter-width: 1rem;
$flexboxgrid-outer-margin: 1rem;
$flexboxgrid-breakpoints: sm 48rem, md 64rem, lg 80rem, xlg 90rem,
  newbreakpoint 120rem;

// Padding

$base-padding: 16px;
$base-padding-inc: 1.333;
$base-padding-medium: $base-padding * $base-padding-inc;
$base-padding-large: $base-padding-medium * $base-padding-inc;
$base-padding-xlarge: $base-padding-large * $base-padding-inc;
$base-padding-xxlarge: $base-padding-xlarge * $base-padding-inc;
$base-padding-small: $base-padding / $base-padding-inc;
$no-padding: 0;

// Box Shadow

$base-box-shadow: 1px 1px 26px rgba(0, 0, 0, 0.1);
