.footer {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 60px;
    bottom: 0;
    left: -30px;
    right: 70%;
    z-index: -1;
  }
  .footer-links {
    text-align: right;
    @include media(small) {
      text-align: center;
    }
    li {
      display: inline-block;
      margin: $base-padding;
    }
  }
  .back-to-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: $color-primary;
    color: $black;
    font-size: $base-fontsize-h3;
    padding: $base-padding;
    cursor: pointer;
    &:after {
      content: "↑";
      position: absolute;
      top: 0;
      left: 21px;
    }
  }
}
