.hero {
  position: relative;
  padding: 50px 0;
  overflow: hidden;
  h1 {
    font-size: $base-fontsize-h1;
    font-family: $font-secondary;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 0;
  }
  h4 {
    font-weight: normal;
  }
  img {
    // margin-top: -100px;
    width: 80%;
    @include media(small) {
      position: absolute;
      top: 50px;
      opacity: 0;
      z-index: -1;
    }
    &.float-img {
      position: absolute;
      top: -50px;
      right: 100px;
      width: 45%;
    }
  }
  // &:before {
  //     content: '';
  //     position:absolute;
  //     top: 50px;
  //     bottom:20%;
  //     right:8%;
  //     left: 50%;
  //     background: $color-primary;
  //     z-index: -1;
  //     @include media(small) {
  //         display:none;
  //     }
  // }
  @include media(small) {
    padding: 80px 20px;
    overflow: hidden;
    margin: -30px;
  }
}
