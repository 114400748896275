@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Amiri|Poppins:wght@400;700&display=swap");
body {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.75);
  line-height: 1.7;
  margin: 40px;
  padding: 0 30px;
  padding-bottom: 50.5173522931px;
  min-height: 100vh;
  background: #fbf7ef url("./Assets/img/Topographic.png") top left no-repeat;
  background-size: 70%;
}
@media screen and (max-width: 767px) {
  body {
    margin: 10px;
    background-size: 200%;
  }
}

.container {
  max-width: 1440px;
  width: 100% !important;
  margin: auto;
}
.container-short {
  max-width: 50%;
  margin: auto;
}
@media screen and (max-width: 767px) {
  .container-short {
    max-width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1000px) {
  .container-short {
    max-width: 70%;
  }
}

.spacer {
  content: "&nbsp;";
  height: 100px;
  width: 100%;
}

a {
  color: #000000;
  text-decoration: none;
  border-bottom: 2px solid #d5d6db;
  transition: all 0.2s ease-in-out;
}
a:hover {
  border-bottom: 2px solid #a8be20;
  color: #5b6711;
}

h1,
h2,
h3,
h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

h1 {
  font-size: 56.8320213298px;
}

h2 {
  font-size: 42.634674666px;
}

h3 {
  font-size: 31.984002px;
}

h4 {
  font-size: 23.994px;
}

.vertical {
  transform: rotate(90deg);
  transform-origin: 3em -2em;
  display: inline-block;
  position: absolute;
  top: 100px;
  left: 0;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
}
@media screen and (max-width: 767px) {
  .vertical {
    left: -20px;
  }
}

.lead {
  font-size: 23.994px;
  margin: 101.0347045863px 0;
}
.lead.border-left {
  padding-left: 21.328px;
  border-left: 5px solid #c5dd34;
}
.lead.green {
  font-size: 31.984002px;
  color: #c5dd34;
  font-weight: bold;
  margin: 0;
}
.lead.green.short {
  max-width: 700px;
}

.font-bold {
  font-weight: bold;
}

strong {
  font-weight: 700;
}

.p-0 {
  padding: 0;
}

.m-0 {
  margin: 0;
}

.img-shadow {
  box-shadow: none;
}

.img-container {
  margin-bottom: 50.5173522931px;
  margin-top: 50.5173522931px;
}

.img-full-width {
  width: 100%;
  position: relative;
  margin: 50.5173522931px 0;
  background-size: cover;
  background-position: center;
}
.img-two-col {
  width: 100%;
  height: 400px;
  position: relative;
  overflow: hidden;
}
.img-two-col img {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.p-0 {
  padding: 0 !important;
}

.p-large {
  padding: 28.430224px !important;
}

.margin-0 {
  margin: 0;
}

.mt-10 {
  margin-top: 100px;
}

.mt-8 {
  margin-top: 80px;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-right-dt {
  text-align: right;
}
@media screen and (max-width: 767px) {
  .text-right-dt {
    text-align: left;
  }
}

.text-small {
  font-size: 13.503375844px;
}

.text-caption {
  margin-bottom: 28.430224px;
  margin-top: 0;
  font-size: 13.503375844px;
}

.color-secondary {
  color: #c5dd34;
}

section {
  position: relative;
  margin-bottom: 100px;
  padding: 0 80px;
}
@media (min-width: 768px) and (max-width: 1000px) {
  section {
    padding: 40px;
  }
}
@media screen and (max-width: 767px) {
  section {
    padding: 0 12px;
  }
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.img-two-col {
  height: 500px;
  background-size: cover;
}

.img-caption {
  padding: 16px;
}

.btn-default {
  background: #c5dd34;
  padding: 16px 28.430224px;
  font-size: 18px;
  color: #000000;
  border-radius: 50px;
  display: inline-block;
  transition: background-color 500ms ease-out;
  border: none;
  cursor: pointer;
}
.btn-default:hover {
  color: #000000;
  transition: background-color 500ms ease-out;
  background: #a8be20;
  border: 0;
}

.case-study-preview {
  padding: 28.430224px;
  transition: background-color 500ms ease-out;
  cursor: pointer;
  margin-bottom: 28.430224px;
}
.case-study-preview h4 {
  margin-bottom: 0;
  font-family: "Poppins", sans-serif;
}
.case-study-preview:hover {
  background: white;
  color: #000000;
  cursor: pointer;
}
.case-study-preview-img {
  height: 300px;
  width: 100%;
  background: #52535f;
  position: relative;
  overflow: hidden;
  margin-bottom: 21.328px;
  background-size: cover;
  background-position: center;
}
@media screen and (max-width: 767px) {
  .case-study-preview {
    padding: 0;
  }
}
.case-study-preview-hz {
  padding: 28.430224px;
  transition: background-color 500ms ease-out;
  cursor: pointer;
  margin-bottom: 28.430224px;
}
.case-study-preview-hz h4 {
  margin-bottom: 0;
  font-family: "Poppins", sans-serif;
}
.case-study-preview-hz-img {
  height: 400px;
  width: 100%;
  background: #52535f;
  position: relative;
  overflow: hidden;
  margin-bottom: 21.328px;
  background-size: cover;
  background-position: center;
  border-radius: 50px;
}
@media screen and (max-width: 767px) {
  .case-study-preview-hz-img {
    height: 200px;
    margin-bottom: 0;
    border-radius: 20px;
  }
}
.case-study-preview-hz .content {
  padding: 28.430224px;
}
@media screen and (max-width: 767px) {
  .case-study-preview-hz .content {
    padding: 0;
  }
}
@media screen and (max-width: 767px) {
  .case-study-preview-hz {
    padding: 0;
  }
}
.case-study-hero {
  height: 800px;
  background-size: cover;
  background-position: center;
  padding: 50.5173522931px;
}
@media screen and (max-width: 767px) {
  .case-study-hero {
    height: 200px;
  }
}
.case-study .case-study-title {
  margin-bottom: 28.430224px;
}

.case-study-url {
  color: #000000;
}
.case-study-url:hover {
  color: #000000;
}

.header {
  background: transparent;
  padding: 28.430224px 0;
  z-index: 99;
}
.header .header-nav {
  text-align: right;
}
.header .header-nav li {
  display: inline-block;
  font-family: "Amiri";
  text-transform: lowercase;
  font-size: 23.994px;
  font-weight: bold;
}
.header .header-nav a {
  border-bottom: 0;
  margin-right: 28.430224px;
  padding-bottom: 3px;
}
.header .header-nav a.active {
  border-bottom: 4px solid #c5dd34;
}

.hero {
  position: relative;
  padding: 50px 0;
  overflow: hidden;
}
.hero h1 {
  font-size: 56.8320213298px;
  font-family: "Amiri";
  font-weight: bold;
  line-height: 1;
  margin-bottom: 0;
}
.hero h4 {
  font-weight: normal;
}
.hero img {
  width: 80%;
}
@media screen and (max-width: 767px) {
  .hero img {
    position: absolute;
    top: 50px;
    opacity: 0;
    z-index: -1;
  }
}
.hero img.float-img {
  position: absolute;
  top: -50px;
  right: 100px;
  width: 45%;
}
@media screen and (max-width: 767px) {
  .hero {
    padding: 80px 20px;
    overflow: hidden;
    margin: -30px;
  }
}

.mason-grid-gallery {
  margin-top: 37.897488592px;
  margin-bottom: 37.897488592px;
}

.footer {
  position: relative;
}
.footer:before {
  content: "";
  position: absolute;
  top: 60px;
  bottom: 0;
  left: -30px;
  right: 70%;
  z-index: -1;
}
.footer .footer-links {
  text-align: right;
}
@media screen and (max-width: 767px) {
  .footer .footer-links {
    text-align: center;
  }
}
.footer .footer-links li {
  display: inline-block;
  margin: 16px;
}
.footer .back-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #c5dd34;
  color: #000000;
  font-size: 31.984002px;
  padding: 16px;
  cursor: pointer;
}
.footer .back-to-top:after {
  content: "↑";
  position: absolute;
  top: 0;
  left: 21px;
}

.timeline {
  position: relative;
  margin: 0;
  max-width: 1000px;
}
.timeline:after {
  content: "";
  position: absolute;
  width: 2px;
  background-color: #d5d6db;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}
.timeline .timeline-box {
  width: 49%;
  position: relative;
}
.timeline .timeline-box:after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  right: -16px;
  background-color: #d5d6db;
  border: 4px solid #fbf7ef;
  top: 45px;
  border-radius: 50%;
  z-index: 1;
}
.timeline .timeline-box:nth-child(odd) {
  margin-left: 50%;
}
.timeline .timeline-box:nth-child(odd):after {
  left: -13px;
}
.timeline .timeline-box:nth-child(odd) .timeline-content {
  text-align: left;
}
.timeline .timeline-box .timeline-content {
  position: relative;
  padding: 16px;
  font-size: 13.503375844px;
  text-align: right;
}
.timeline .timeline-box .number {
  font-size: 42.634674666px;
}
.timeline .timeline-box h1, .timeline .timeline-box h2, .timeline .timeline-box h3, .timeline .timeline-box h4 {
  margin-top: 0;
}

.article {
  padding-left: 60px;
  position: relative;
}
.article-number {
  font-size: 170.4960639893px;
  position: absolute;
  top: -20px;
  left: 0;
  color: #c5dd34;
  z-index: -1;
  margin: 0;
  line-height: 1px;
}
@media screen and (max-width: 767px) {
  .article {
    padding-left: 0;
  }
}/*# sourceMappingURL=style.css.map */