@mixin media($size) {
    @if $size == small {
        @media screen and (max-width: 767px) {
            @content;
        }
    }
    @if $size == medium {
        @media (min-width: 768px) and (max-width: 1000px) {
            @content;
        }
    }
}