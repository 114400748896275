.case-study {
  &-preview {
    padding: $base-padding-large;
    -webkit-transition: background 500ms ease-out;
    -moz-transition: background 500ms ease-out;
    -o-transition: background 500ms ease-out;
    transition: background-color 500ms ease-out;
    cursor: pointer;
    margin-bottom: $base-padding-large;
    h4 {
      margin-bottom: 0;
      font-family: $font-primary;
    }
    &:hover {
      background: white;
      color: $black;
      cursor: pointer;
    }
    &-img {
      height: 300px;
      width: 100%;
      background: $neutral-dark;
      position: relative;
      overflow: hidden;
      margin-bottom: $base-padding-medium;
      background-size: cover;
      background-position: center;
    }
    @include media(small) {
      padding: 0;
    }
  }
  &-preview-hz {
    padding: $base-padding-large;
    -webkit-transition: background 500ms ease-out;
    -moz-transition: background 500ms ease-out;
    -o-transition: background 500ms ease-out;
    transition: background-color 500ms ease-out;
    cursor: pointer;
    margin-bottom: $base-padding-large;

    h4 {
      margin-bottom: 0;
      font-family: $font-primary;
    }
    &-img {
      height: 400px;
      width: 100%;
      background: $neutral-dark;
      position: relative;
      overflow: hidden;
      margin-bottom: $base-padding-medium;
      background-size: cover;
      background-position: center;
      border-radius: 50px;
      @include media(small) {
        height: 200px;
        margin-bottom: 0;
        border-radius: 20px;
      }
    }
    .content {
      padding: $base-padding-large;
      @include media(small) {
        padding: 0;
      }
    }

    @include media(small) {
      padding: 0;
    }
  }
  &-hero {
    height: 800px;
    background-size: cover;
    background-position: center;
    padding: $base-padding-xxlarge;

    @include media(small) {
      height: 200px;
    }
  }
  .case-study-title {
    margin-bottom: $base-padding-large;
  }
}

.case-study-url {
  color: $black;
  &:hover {
    color: $black;
  }
}
