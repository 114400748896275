.timeline {
    position:relative;
    margin: 0;
    max-width: 1000px;
    &:after {
        content: '';
        position: absolute;
        width: 2px;
        background-color: $neutral-med;
        top: 0;
        bottom: 0;
        left: 50%;
        margin-left: -3px;
    }
    .timeline-box {
        width: 49%;
        position:relative;
        &:after {
            content: '';
            position: absolute;
            width: 15px;
            height: 15px;
            right: -16px;
            background-color: $neutral-med;
            border: 4px solid $neutral;
            top: 45px;
            border-radius: 50%;
            z-index: 1;
        }
        &:nth-child(odd) {
            margin-left:50%;
            &:after {
                left: -13px;
            }
            .timeline-content {
                text-align: left;
            }
        }
        .timeline-content {
            position:relative;
            padding: $base-padding;
            font-size: $base-fontsize-small;
            text-align: right;
        }
        .number {
            font-size: $base-fontsize-h2;
        }
        h1, h2, h3, h4 {
            margin-top:0;
        }
    }
}