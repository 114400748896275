.btn-default {
  background: $color-primary;
  padding: $base-padding $base-padding-large;
  font-size: $base-fontsize;
  color: $black;
  border-radius: 50px;
  display: inline-block;
  -webkit-transition: background 500ms ease-out;
  -moz-transition: background 500ms ease-out;
  -o-transition: background 500ms ease-out;
  transition: background-color 500ms ease-out;
  border: none;
  cursor: pointer;
  &:hover {
    color: $black;
    transition: background-color 500ms ease-out;
    background: darken($color-primary, 10%);
    border: 0;
  }
}
