.row {
    margin-left: 0 !important;
    margin-right:0 !important;
}

.img-two-col {
    height: 500px;
    background-size: cover;
}

.img-caption {
    padding: $base-padding;
}