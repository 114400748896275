.article {
    padding-left: 60px;
    position: relative;
    &-number {
        font-size: $base-fontsize-h1 * 3;
        position:absolute;
        top:-20px;
        left:0;
        color: $color-primary;
        z-index: -1;
        margin:0;
        line-height: 1px;
    }
    @include media(small) {
        padding-left: 0;
    }
}