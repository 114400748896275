.header {
    background: transparent;
    padding: $base-padding-large 0;
    z-index:99;
    .header-nav {
        text-align: right;
        li {
            display: inline-block;
            font-family: $font-secondary;
            text-transform: lowercase;
            font-size: $base-fontsize-h4;
            font-weight: bold;
        }
        a {
            border-bottom: 0;
            margin-right: $base-padding-large;
            padding-bottom: 3px;
            &.active {
                border-bottom: 4px solid $color-secondary;
            }
        }
    }
}