body {
  font-family: $font-primary;
  font-size: $base-fontsize;
  color: rgba(0, 0, 0, 0.75);
  line-height: 1.7;
  margin: 40px;
  padding: 0 30px;
  padding-bottom: $base-padding-xxlarge;
  min-height: 100vh;
  background: $neutral url("./Assets/img/Topographic.png") top left no-repeat;
  background-size: 70%;
  @include media(small) {
    margin: 10px;
    background-size: 200%;
  }
}

.container {
  max-width: $grid-max-width;
  width: 100% !important;
  margin: auto;
  &-short {
    max-width: 50%;
    margin: auto;
    @include media(small) {
      max-width: 100%;
    }
    @include media(medium) {
      max-width: 70%;
    }
  }
}

.spacer {
  content: "&nbsp;";
  height: 100px;
  width: 100%;
}

// Fonts

a {
  color: $black;
  text-decoration: none;
  border-bottom: 2px solid $neutral-med;
  transition: all 0.2s ease-in-out;
  &:hover {
    border-bottom: 2px solid darken($color-primary, 10%);
    color: darken($color-primary, 30%);
  }
}

h1,
h2,
h3,
h4 {
  font-family: $font-primary;
  font-weight: 700;
}

h1 {
  font-size: $base-fontsize-h1;
}

h2 {
  font-size: $base-fontsize-h2;
}

h3 {
  font-size: $base-fontsize-h3;
}

h4 {
  font-size: $base-fontsize-h4;
}

.vertical {
  transform: rotate(90deg);
  transform-origin: 3em -2em;
  display: inline-block;
  position: absolute;
  top: 100px;
  left: 0;
  font-family: $font-primary;
  text-transform: uppercase;
  @include media(small) {
    left: -20px;
  }
}

.lead {
  font-size: $base-fontsize-h4;
  margin: ($base-padding-xxlarge * 2) 0;
  &.border-left {
    padding-left: $base-padding-medium;
    border-left: 5px solid $color-primary;
  }

  &.green {
    font-size: $base-fontsize-h3;
    color: $color-secondary;
    font-weight: bold;
    margin: 0;
    &.short {
      max-width: 700px;
    }
  }
}

.font-bold {
  font-weight: bold;
}

strong {
  font-weight: $font-weight-bold;
}

.p-0 {
  padding: $no-padding;
}

.m-0 {
  margin: $no-padding;
}

// Images

.img-shadow {
  box-shadow: none;
}

.img-container {
  margin-bottom: $base-padding-xxlarge;
  margin-top: $base-padding-xxlarge;
}

.img {
  &-full-width {
    width: 100%;
    position: relative;
    margin: $base-padding-xxlarge 0;
    background-size: cover;
    background-position: center;
  }
  &-two-col {
    width: 100%;
    height: 400px;
    position: relative;
    overflow: hidden;
    img {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

// Padding

.p-0 {
  padding: 0 !important;
}

.p-large {
  padding: $base-padding-large !important;
}

// Margins

.margin-0 {
  margin: 0;
}
.mt-10 {
  margin-top: 100px;
}
.mt-8 {
  margin-top: 80px;
}

// Text Helpers

.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-right-dt {
  text-align: right;
  @include media(small) {
    text-align: left;
  }
}
.text-small {
  font-size: $base-fontsize-small;
}
.text-caption {
  margin-bottom: $base-padding-large;
  margin-top: 0;
  font-size: $base-fontsize-small;
}

.color-secondary {
  color: $color-secondary;
}

section {
  position: relative;
  margin-bottom: 100px;
  padding: 0 80px;
  @include media(medium) {
    padding: 40px;
  }
  @include media(small) {
    padding: 0 12px;
  }
}
